/* eslint-disable import/first */

// Require polyfills
import './polyfills/array.findindex';
import './polyfills/childnode-remove';
import './modules/gravity-forms';

// Import libraries and polyfills
import Promise from 'promise-polyfill';
import 'whatwg-fetch';
import 'classlist-polyfill';
import rafPolyfill from './polyfills/request-animation-frame';
import 'regenerator-runtime/runtime';

// Handler and Enhancer utility
import handle from './modules/handle';
import enhance from './modules/enhance';

// Import functions that are executed on DOMready regardless of DOM
import { onDomReady } from './modules/ready';
import { enhancer as scrollListener } from './modules/scroll-listener';
import { enhancer as responsive } from './modules/responsive';
import { default as disableHoverStylesOnScroll } from './modules/disable-hover-styles-on-scroll';

// Import handlers
import { handler as classToggler } from './modules/class-toggler';
import { handler as cookieBarAccept } from './modules/cookie-bar';
import { handler as faqItemToggler } from './modules/faq';
import { handler as gtmEventHandler } from './modules/gtm-event';
import { handler as mobileMenuToggle } from './modules/mobile-menu';
import { handler as scrollAnchor } from './modules/scroll-anchor';

// Import enhancers
import { enhancer as addressAutofill } from './modules/address-autofill';
import { enhancer as cookieBar } from './modules/cookie-bar';
import { enhancer as faqList } from './modules/faq';
import { enhancer as formValidation } from './modules/form-validation';
import { enhancer as conditionalFields } from './modules/conditional-fields';
import { enhancer as gtmEventEnhancer } from './modules/gtm-event';
import { enhancer as imageSlider } from './modules/image-slider';
import { enhancer as labelAnimation } from './modules/label-animation';
import { enhancer as mobileMenu } from './modules/mobile-menu';
import { enhancer as newsletterSignup } from './modules/newsletter-signup';
import { enhancer as objectFit } from './modules/object-fit';
import { enhancer as popupMenu } from './modules/popup-menu';
import { enhancer as siteHeader } from './modules/site-header';
import { enhancer as ajaxifyForm } from './modules/ajaxify-form';

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change

  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;

  if (typeof window.Promise === 'undefined') {
    window.Promise = Promise;
  }
};

const main = () => {
  executeOnReady();
  handle({
    classToggler,
    cookieBarAccept,
    faqItemToggler,
    gtmEventHandler,
    mobileMenuToggle,
    scrollAnchor,
  });
  enhance({
    addressAutofill,
    cookieBar,
    conditionalFields,
    faqList,
    formValidation,
    gtmEventEnhancer,
    imageSlider,
    labelAnimation,
    mobileMenu,
    newsletterSignup,
    objectFit,
    popupMenu,
    siteHeader,
    ajaxifyForm,
  });
};

onDomReady(main);
